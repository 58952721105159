
.footer-blur-1{
  bottom: 0;
  width:32rem;
  height: 23rem;
  right:15%;
  filter: blur(250px);
  background: rgba(153, 205, 50, 0.32);
 
}
.footer-blur-2{
  bottom: 0;
  width:32rem;
  height: 23rem;
  left:15%;
  filter: blur(250px);
 
}
.bmi {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 100px 0 50px 0;
   
  
  }
  .bmi h1 {
    font-size: 4rem;
    margin: 0 auto;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: whitesmoke;
  }
  .bmi .container {
    display: flex;
    gap: 50px;
    min-width: 600px;
    max-width: 1000px;
    color: whitesmoke;
  
  }
  .bmi .container .wrapper {
    flex: 1;
  }
  .bmi .container .wrapper:last-child {
    overflow: hidden;
  }
  .bmi .container .wrapper:last-child img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 20px 25px 20px black;
  }
  .bmi .container .wrapper form {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    border:10px solid var(--orange);
    border-radius: 20px;
    padding:20px;
  }
  .bmi .container .wrapper form div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 22px;
    font-weight: 500;
  }
  .bmi .container .wrapper form div input,
  .bmi .container .wrapper form div select {
    padding: 7px 4px;
    font-size: 22px;
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    color: antiquewhite;
  }
  .bmi .container .wrapper form div option {
    color: #111;
  }
  .bmi .container .wrapper form div input:focus,
  .bmi .container .wrapper form div select:focus {
    outline: none;
  }
  .bmi .container .wrapper form button {
    border: none;
    padding: 10px 40px;
    font-size: 22px;
    font-weight: 500;
    color:var(--orange);
    background: #fff;
    transition: 0.4s all ease;
    cursor: pointer;
    border-radius: 5px;
  }
  .bmi .container .wrapper form button:hover{
    color:white;
    background-color: var(--orange);

  }

  @media (max-width: 1520px) {
    .bmi {
      padding: 100px 20px 50px 20px;
    }
    .bmi .container {
      min-width: 100%;
    }
  }
  @media (max-width: 735px) {
    .bmi .container {
      flex-direction: column-reverse;
      gap: 1px;
      padding:20px;
      text-align: center;
    

    }
    .wrapper img{
      display: none;
    }
    .wrapper form{
      width: 403px;
      margin: 5px auto;
    }
    .bmi h1{
      font-size: 2rem;
    }
    .bmi{
      padding:50px;
    }
  }